import { updateUser } from '@core/queries/user'
import * as Realm from 'realm-web'
import jwtDefaultConfig from './jwtDefaultConfig'
import endpoints from '@/libs/endpoints'
import router from '@/router'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = {
    ...jwtDefaultConfig,
  }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For returning to login if user session expired (error 401)
  hasAlreadyBeenLogout = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.realm = new Realm.App({
      id: endpoints.app_id,
    })
    this.jwtConfig = {
      ...this.jwtConfig,
      ...jwtOverrideConfig,
    }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      async config => {
        // config.headers['X-Project-Name'] = "ACHS"
        // Get token from localStorage
        let accessToken = ''
        let isLoggedIn = ''
        if (this.realm.currentUser) {
          accessToken = this.realm.currentUser.accessToken
          isLoggedIn = this.realm.currentUser.isLoggedIn
        }

        // If token is present add it to request's Authorization Header
        if (accessToken && isLoggedIn) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        } else {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} `
        }
        // eslint-disable-next-line no-param-reassign
        config.url = ''
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {

          // If there is no realm currentUser then logout
          if (!this.realm.currentUser) {
            if (!this.hasAlreadyBeenLogout) {
              this.hasAlreadyBeenLogout = true

              // Remove tokens
              localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
              localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
        
              // Remove userData and clientData from localStorage
              localStorage.removeItem('userData')
              localStorage.removeItem('clientData')
            
              // Redirect to login page
              router.push({ name: 'auth-login', query: { session: 'expired' } })
            }
          }
          else if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.realm.currentUser.refreshAccessToken().then(() => {
              this.isAlreadyFetchingAccessToken = false
              const { accessToken, refreshToken } = this.realm.currentUser
              // Update accessToken in localStorage
              this.setToken(accessToken)
              this.setRefreshToken(refreshToken)

              this.onAccessTokenFetched(accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login({ email, password }) {
    // Update this variable that is used in error 401 redirection
    this.hasAlreadyBeenLogout = false

    const cred = Realm.Credentials.emailPassword(email, password)
    return this.realm.logIn(cred)
  }

  loginAsAnonymousUser() {
    const cred = Realm.Credentials.anonymous()
    return this.realm.logIn(cred)
  }

  register({ email, password, username }) {
    return this.realm.emailPasswordAuth.registerUser({
      email,
      password,
      username,
    })
  }

  async updateDefaultData({
    id,
    username,
    project = '',
    role = 'client',
    // eslint-disable-next-line camelcase
    worker_id = null,
    // eslint-disable-next-line camelcase
    client_id = null,
    ability = [
      {
        action: 'manage',
        subject: 'all',
      },
    ],
    expirationDate = null
  }) {
    const customData = {
      role,
      worker_id,
      project,
      client_id,
      username,
      ability,
      extras: {
        eCommerceCartItemsCount: 0,
      },
      expirationDate
    }

    const query = {
      operationName: 'updateOneUser',
      query: updateUser,
      variables: {
        query: {
          id,
        },
        data: {
          custom_data: customData,
        },
      },
    }
    return this.axiosIns.post('', {
      ...query,
    })
  }

  logout() {
    return this.realm?.currentUser?.logOut()
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  getLocalUserData = () => {
    try {
      const d = localStorage.getItem('userData')
      const parsedD = JSON.parse(d)
      return parsedD
    } catch (e) {
      console.error(e)
      return {}
    }
  }
}
